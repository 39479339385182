import { useDispatch, useSelector } from "react-redux";
import signInBackgroundImage from "./../../../assets/graphics/sign_in_background.png";
import Toast from "../../Toast";
import { useNavigate } from "react-router";

import {
  setLoading,
  unsetLoading,
} from "../../../../features/loader/loaderSlice";
import Button from "../../../primitives/Button";
import bpLogo from "../../../assets/graphics/Bulletproof_Logo.png";
import SignUpForm from "./SignUpForm";
import { fetcher } from "../../../../logic/util/hooks";
import Loader from "../../Loader/Loader";
import mixpanel from "mixpanel-browser";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useSearchParams } from "react-router-dom";
import { useCallback, useEffect } from "react";

export default function Signup() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoading = useSelector((state: any) => state.loader.isLoading);
  const [searchParams] = useSearchParams();
  const isGoogleSignUp = Boolean(searchParams.get("isGoogleSignUp"));
  // console.log({ isGoogleSignUp });

  const onGoogleSignUp = useCallback(() => {
    dispatch(setLoading());
    fetcher("/auth/google/google_social_signin?state=googleSignIn")
      .then((res) => {
        if (res) {
          window.localStorage.setItem("sso_signup", true.toString());
          window.location.href = res.url;
        }
      })
      .catch((err) => {
        console.log("Err in Google signin", err);
        dispatch(unsetLoading());
      });
  }, [dispatch]);

  const onSignUpClick = () => {
    navigate("/login");
  };

  useEffect(() => {
    if (isGoogleSignUp) {
      onGoogleSignUp();
    }
  }, [isGoogleSignUp, onGoogleSignUp]);

  if (isGoogleSignUp) return null;

  return (
    <div
      className="text-black_primary bg-center bg-no-repeat bg-cover bg-orange_bg flex justify-center items-start 3xl:items-center h-full min-h-screen xs:h-full"
      style={{ backgroundImage: `url(${signInBackgroundImage})` }}
    >
      <div
        className={`bg-white mt-32 p-5 m-5 pb-1 sm:pb-0 w-full sm:w-auto flex flex-col items-center shadow-none sm:shadow-nav rounded-none sm:rounded-lg gap-y-10 sm:gap-y-4`}
      >
        <div className="text-base flex gap-x-4 text-black100">
          Already have an account?
          <button
            onClick={onSignUpClick}
            className="text-base text-green_primary underline "
          >
            Sign in
          </button>
        </div>
        <div
          className={`w-full sm:w-auto pb-2 sm:pb-0 flex flex-col gap-2 px-6 sm:px-0`}
        >
          <div className="flex justify-center flex-col items-center">
            <LazyLoadImage
              src={bpLogo}
              width={"200px"}
              height={"54px"}
              alt="bullet proof inbox logo"
              className=""
            />
            <p className="text-center text-md font-custom text-green_primary mt-7 max-w-96 sm:hidden mb-6">
              Take control of your inbox.
              <br />
              Start your 30 day free trial today.
            </p>
            <p className="text-center text-md font-custom text-green_primary mt-4 max-w-123 hidden sm:block">
              Take control of your inbox.
              <br />
              Start your 30 day free trial today.
            </p>
          </div>
          <div className="flex flex-col gap-2">
            <div className="flex justify-center mb-9 sm:mb-0">
              <Button
                format={{
                  type: "signup",
                  isFilled: false,
                  content: "Sign up with Google",
                  // isLoading: props.isSubmitting,
                  width: "w-full",
                  icon: "google",
                  // onclick: onGoogleSignUp,
                  onclick: () => {
                    mixpanel.track("Clicked: 'Google Sign-Up' button");
                    onGoogleSignUp();
                  },
                }}
              />
            </div>
            <div className="flex flex-col gap-4">
              <div className="flex items-center sm:mt-4">
                <div className="flex-grow border-t border-gray3"></div>
                <div className="px-2 text-gray1">
                  or sign up with your email address
                </div>
                <div className="flex-grow border-t border-gray3"></div>
              </div>
              <div className="flex flex-col justify-items-center items-center">
                <SignUpForm content="Continue" />
                <Toast />
              </div>
            </div>
          </div>
          <div className="font-custom font-normal text-sm text-gray1 mt-6 text-center sm:mb-5">
            {"By creating an account, you agree to our "}
            <a
              href="https://www.bulletproofinbox.com/privacy-policy"
              target={"_blank"}
              className="underline"
            >
              {"Privacy Policy"}
            </a>
            {" and "}
            <a
              href="https://www.bulletproofinbox.com/terms-of-service"
              target={"_blank"}
              className="underline"
            >
              {"Terms of Service"}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
