import React from "react";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

interface DonutChartProps {
    series: number[];
    width?: string | number;
    height?: string | number;
}

const DonutChart: React.FC<DonutChartProps> = ({ series, width = "100%", height = 190 }) => {
    const isZeroData = series.every(value => value === 0);
    const adjustedSeries = isZeroData ? [1] : series;
    const adjustedColors = isZeroData ? ['#B0B0B0'] : ['#2C703F', '#F3DE59', '#8B0000'];
    const legendColors = ['#2C703F', '#F3DE59', '#8B0000'];

    const options: ApexOptions = {
        labels: ["Approved", "Evaluating", "Blocked"],
        colors: adjustedColors,
        dataLabels: {
            enabled: false,
            style: {
                fontSize: '16px',
            }
        },
        chart: {
            width: 350,
            height: 192,
            type: "donut",
        },
        legend: {
            show: true,
            position: "right",
            markers: {
                fillColors: legendColors,
            },
            formatter: function (val, opts) {
                const seriesValue = opts.w.globals.series[opts.seriesIndex];
                const totalSeries = opts.w.globals.series.reduce((acc: number, value: number) => acc + value, 0);
                const percentage = totalSeries === 0 ? 0 : ((seriesValue / totalSeries) * 100).toFixed(2);
                if (!isZeroData) {
                    return `<div style="display: flex; flex-direction: column; align-items: flex-start;">
                          <span>${val}</span>
                          <span style="font-size: 12px; color: grey;">${seriesValue} (${percentage}%)</span>
                        </div>`;
                } else {
                    return `<div style="display: flex; flex-direction: column; align-items: flex-start;">
                          <span>${val}</span>
                          <span style="font-size: 12px; color: grey;"> __ __</span>
                        </div>`;
                }
            }
        },
        plotOptions: {
            pie: {
                donut: {
                    size: '75%',
                    labels: {
                        show: true,
                        total: {
                            show: true,
                            label: isZeroData ? "Total Mails" : "Shielded",
                            fontSize: "12px",
                            formatter: function (w) {
                                if (isZeroData) {
                                    return "__";
                                } else {
                                    // Calculate the sum of Evaluating and Blocked
                                    const evaluatingIndex = 1;
                                    const blockedIndex = 2;
                                    const totalShielded = w.globals.seriesTotals[evaluatingIndex] + w.globals.seriesTotals[blockedIndex];
                                    return `${totalShielded}`;
                                }
                            }
                        }
                    }
                }
            },
        },
        stroke: {
            width: 0,
            colors: ['#fff'],
        },
        tooltip: {
            enabled: !isZeroData,
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {
                        width: '100%',
                        height: 350,
                        type: "donut",
                    },

                    legend: {
                        position: "right"
                    }
                }
            }
        ]
    };

    return (
        <div>
            <div style={{ width, margin: "0" }}>
                <div id="chart" style={{ width: "100%", height }}>
                    <ReactApexChart
                        options={options}
                        series={adjustedSeries}
                        type="donut"
                        height={height}
                    />
                </div>
            </div>
        </div>
    );
}

export default DonutChart;
