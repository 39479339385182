import axios, { AxiosInstance, AxiosResponse } from "axios";
import jwtDecode from "jwt-decode";
import "../../ui/styles/sessionexpired.css";
import swal from "sweetalert";
import { useLocation } from "react-router";
import { useEffect } from "react";

interface Routes {
  auth: string;
  pins: string;
  waitlist: string;
  accounts: string;
  platforms: string;
  contacts: string;
  responses: string;
  domains: string;
  onboarding: string;
  stripe: string;
}
interface DecodedToken {
  id: string;
  exp: number;
}
// export let token = localStorage.getItem("jwtToken");

export let instance: AxiosInstance = axios.create({
  withCredentials: true,
  timeout: 300000,
  baseURL: `${process.env.REACT_APP_API}`,
  // headers: { Authorization: token ? token : "token" },
});
instance.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem("jwtToken");
    if (token && config && config.headers)
      config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const token = localStorage.getItem("jwtToken");
    if (
      token &&
      (error.response?.status === 403 || error.response?.status === 401)
    ) {
      swal("Session Expired", "Please sign in again").then(() => {
        window.location.assign("/login");
      });
    }
    if (error.response?.status === 500) {
      // window.location.assign("/500");
    }
    return Promise.reject(error);
  },
);

const routes: Routes = {
  auth: "auth",
  pins: "pins",
  waitlist: "waitlist",
  accounts: "resources/accounts",
  platforms: "resources/platforms",
  contacts: "resources/contacts",
  responses: "resources/responses",
  domains: "resources/domains",
  onboarding: "onboarding",
  stripe: "stripe",
};
export const fetcher = (url: string) =>
  instance.get(url).then((res) => res.data);
export const post = async (
  route: keyof Routes,
  path?: string,
  payload?: any,
  blockExtend = false,
): Promise<any> => {
  const data = await instance.post(`/${routes[route]}/${path}`, payload);
  if (!blockExtend) {
    // await extend();
    // await revalidateLiveQueries();
  }
  return data;
};
export const update = async (
  route: keyof Routes,
  path: string,
  payload: any,
) => {
  const data = await instance.put(`/${routes[route]}/${path}`, payload);
  // await revalidateLiveQueries();
  //   await extend();
  return data;
};
export const del = async (route: keyof Routes, path: any) => {
  const data = await instance.delete(`/${routes[route]}/${path}`);
  // await revalidateLiveQueries();
  //   await extend();
  return data;
};

export async function extend() {
  let token = localStorage.getItem("jwtToken");
  if (!token) return;

  const { id, exp } = jwtDecode(token) as DecodedToken;
  const refreshCutOff = Date.now() + 60000 * 28;

  if (refreshCutOff > exp * 1000) {
    try {
      const request = await post("auth", `extend/${id}`, {}, true);
      localStorage.setItem("jwtToken", request.data);
    } catch (error) {
      console.log(error);
    }
  }
}