import React from "react";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

interface BarChartProps {
  series: { name: string; data: number[] }[];
  categories?: string[];
}

const BarChart: React.FC<BarChartProps> = ({ series, categories }) => {
  const options: ApexOptions = {
    chart: {
      type: 'bar',
      height: 350,
      toolbar: {
        show: false
      },
      events: {
        legendClick: function (chartContext: any, seriesIndex?: number) {
          if (typeof seriesIndex !== 'number') {
            return;
          }
          const seriesNames: string[] = chartContext.w.globals.seriesNames;
          const clickedSeriesName: string = seriesNames[seriesIndex];

          const visibleSeriesIndices = chartContext.w.globals.series
            .map((_: any, i: number) => {
              return !chartContext.w.globals.collapsedSeriesIndices.includes(i) ? i : null;
            })
            .filter((i: null): i is any => i !== null);

          if (visibleSeriesIndices.length === 1 && visibleSeriesIndices[0] === seriesIndex) {
            seriesNames.forEach((name: string) => {
              chartContext.showSeries(name);
            });
          } else {
            seriesNames.forEach((name: string) => {
              chartContext.hideSeries(name);
            });
            chartContext.showSeries(clickedSeriesName);
          }
        }
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '50%',
        borderRadius: 4,
      },
    },
    colors: ['#2C703F', '#F3DE59', '#8B0000'],
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 5,
      colors: ['transparent']
    },
    xaxis: {
      categories: categories || ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      labels: {
        show: true,
        style: {
          fontSize: '12px'
        },
        rotate: -45
      }
    },
    fill: {
      opacity: 1
    },
    tooltip: {
      y: {
        formatter: function (val: number) {
          return val.toString();
        }
      }
    },
    legend: {
      position: 'bottom',
      show: true,
      horizontalAlign: 'left',
      offsetX: 0,
      offsetY: 0,
      itemMargin: {
        horizontal: 10,
      },
      onItemClick: {
        toggleDataSeries: false
      }
    },
    responsive: [
      {
        breakpoint: 680,
        options: {
          plotOptions: {
            bar: {
              columnWidth: '55%',
            }
          },
          stroke: {
            width: 2,
          },
          xaxis: {
            categories: categories || ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            labels: {
              show: true,
              style: {
                fontSize: '12px'
              },
              rotate: -45
            }
          },
          legend: {
            position: 'bottom',
            horizontalAlign: 'left',
            offsetX: 0,
            offsetY: 0,
            itemMargin: {
              horizontal: 10,
            }
          }
        }
      }
    ]
  };

  return (
    <div className="overflow-x-auto">
      <div style={{ minWidth: '1000px' }}>
        <ReactApexChart options={options} series={series} type="bar" height={350} />
      </div>
    </div>
  );
}

export default BarChart;
