import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router";

const routeDescriptions = {
  "/register":
    "Create an account with Bulletproof Inbox and protect your email from spam, phishing, and other threats. Join us for a safer email experience.",
  "/login":
    "Access your Bulletproof Inbox account and manage your emails securely. Log in now for enhanced email protection and privacy.",
  "/personalize":
    "Customize your Bulletproof Inbox settings for a tailored and more secure email experience. Make email work better for you.",
  "/forgot-password":
    "Forgot your password? Reset it quickly and regain access to your Bulletproof Inbox account with ease.",
  "/reset-password/":
    "Securely create a new password for your Bulletproof Inbox account to restore access and continue protecting your email.",
  "/survey/":
    "Participate in our survey to share your experience with Bulletproof Inbox. Your feedback helps us improve email security and service.",
  "/data-info":
    "Learn more about how Bulletproof Inbox protects your data and ensures your privacy. Your security is our priority.",
  "/404": `The page you’re looking for cannot be found. Return to Bulletproof Inbox to manage your email securely.`,
  "/waitlist-survey":
    "Share your input on Bulletproof Inbox and help us improve your email experience. Your opinion matters.",
  "/feedback":
    "Share your thoughts and feedback with Bulletproof Inbox. Your input helps us create a more secure and efficient email service.",
  "/500": `Oops! Something went wrong on our end. We’re working to fix it. Please try again later.`,
  "/invalid-permissions":
    "You do not have the necessary permissions to view this page. Contact support for assistance with access.",
  "/icf2023/contact-form":
    "Reach out to Bulletproof Inbox with any questions or concerns. We're here to help you with email security and account management.",
  "/message-delivered":
    "Your message has been delivered securely through Bulletproof Inbox. Protecting your communication is our mission.",
  "/app/connect-account":
    "Link your account to Bulletproof Inbox and enhance your email security. Enjoy seamless integration and protection.",
  "/app/merge/": `You’re being redirected to a new platform. Experience the latest features and updates from Bulletproof Inbox.`,
  "/app/email-settings/":
    "Adjust your email settings to optimize security and performance. Take control of your inbox with Bulletproof Inbox.",
  "/app/domains/":
    "Manage your domain settings to enhance email security and reduce spam. Keep your communication safe with Bulletproof Inbox.",
  "/app/email-accounts":
    "View and manage all your email accounts from one secure dashboard. Bulletproof Inbox makes managing your email simple.",
  "/app/profile":
    "Update your profile information to keep your account secure and personalized. Manage your preferences with Bulletproof Inbox.",
  "/app/requests":
    "Review and manage your requests submitted to Bulletproof Inbox. We prioritize your security needs.",
  "/app/approved-sender-list":
    "Control who can send you emails by managing your approved sender list. Keep your inbox clean and safe with Bulletproof Inbox.",
  "/app/rereview-domains":
    "Ensure your domains meet the security requirements of Bulletproof Inbox. Keep your email domain secure from threats.",
  "/app/subscription":
    "Update or modify your subscription plan with Bulletproof Inbox. Enjoy uninterrupted email security and services.",
  "/app/payment":
    "View and manage your payment information securely. Bulletproof Inbox ensures a safe transaction process.",
  "/app/delete-account":
    "Permanently delete your Bulletproof Inbox account. Ensure your decision and manage your data responsibly.",
};

export const DynamicMetaDescription = () => {
  const [content, setContent] = useState("");
  // console.log({ content });

  const location = useLocation();

  useEffect(() => {
    const route = Object.keys(routeDescriptions).filter((route) => {
      return location.pathname.toLowerCase().includes(route.toLowerCase());
    })[0];

    setContent(
      routeDescriptions[route as keyof typeof routeDescriptions] ||
        "Bulletproof Inbox",
    );
  }, [location]);

  return (
    <Helmet>
      <meta name="description" content={content} />
    </Helmet>
  );
};
