import { useEffect } from "react";
import { useLocation } from "react-router";

const routeTitles = {
  "/register": "Sign Up for Bulletproof Inbox - Secure Your Email",
  "/login": "Login to Bulletproof Inbox - Secure Email Management",
  "/personalize": "Personalize Your Bulletproof Inbox Experience",
  "/forgot-password": "Reset Your Bulletproof Inbox Password",
  "/reset-password/": "Set a New Password for Bulletproof Inbox",
  "/survey/": "Bulletproof Inbox - Complete Our Survey",
  "/data-info": "Bulletproof Inbox - Data Information and Security",
  "/404": "Page Not Found - Bulletproof Inbox",
  "/waitlist-survey": "Bulletproof Inbox - Waitlist Survey",
  "/feedback": "Bulletproof Inbox - We Value Your Feedback",
  "/500": "Error Occurred - Bulletproof Inbox",
  "/invalid-permissions": "Access Denied - Bulletproof Inbox",
  "/icf2023/contact-form": "Bulletproof Inbox - Contact Us",
  "/message-delivered": "Bulletproof Inbox - Message Successfully Delivered",
  "/app/connect-account": "Connect Your Account to Bulletproof Inbox",
  "/app/merge/": "Redirecting to New Platform - Bulletproof Inbox",
  "/app/email-settings/": "Manage Your Email Settings - Bulletproof Inbox",
  "/app/domains/": "Configure Your Domains - Bulletproof Inbox",
  "/app/email-accounts": "Bulletproof Inbox - Manage Your Email Accounts",
  "/app/profile": "Bulletproof Inbox - Manage Your Profile",
  "/app/requests": "View Your Requests - Bulletproof Inbox",
  "/app/approved-sender-list":
    "Manage Your Approved Senders - Bulletproof Inbox",
  "/app/rereview-domains": "Re-Review Domains for Security - Bulletproof Inbox",
  "/app/subscription": "Manage Your Subscription - Bulletproof Inbox",
  "/app/payment": "Manage Payments - Bulletproof Inbox",
  "/app/delete-account": "Delete Your Bulletproof Inbox Account",
};

export default function usePageTitle() {
  const location = useLocation();

  useEffect(() => {
    const route = Object.keys(routeTitles).filter((route) => {
      return location.pathname.toLowerCase().includes(route.toLowerCase());
    })[0];

    // console.log(route);

    const pageTitle =
      routeTitles[route as keyof typeof routeTitles] || "Bulletproof Inbox";
    document.title = pageTitle;
  }, [location]);
}
