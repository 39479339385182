import React, { useEffect, useState } from 'react';
import Footer from "../../primitives/Footer";
import BarChart from "./BarChart";
import DonutChart from "./DonutChart";
import ToggleButton from './ToggleButton';
import ClockGreen from "../../assets/icons/clock-green-icon.svg";
import ClockGray from "../../assets/icons/clock-gray-icon.svg";
import { useSelector } from 'react-redux';
import { fetcher } from '../../../logic/util/hooks';
import Loader from '../../primitives/Loader/Loader';

export const timeSavings = (evaluating: any, blocked: any): { hours: number; minutes: number } => {
    const evaluatingNumber = Number(evaluating);
    const blockedNumber = Number(blocked);
    const totalSeconds = (evaluatingNumber + blockedNumber) * 10;
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    return { hours, minutes };
};

interface FrequencyBasedResponse {
    approved: number;
    evaluating: number;
    blocked: number;
}

interface MonthlySeriesData {
    name: string;
    data: number[];
}

const StatsDashboard: React.FC = () => {
    const [activeToggle, setActiveToggle] = useState('monthly');
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState<FrequencyBasedResponse | null>(null);
    const [sixtyDays, setSixtydays] = useState<FrequencyBasedResponse | null>(null);
    // const [thirtyDays, setThirtyDays] = useState<FrequencyBasedResponse | null>(null);
    const [yearlyData, setYearlyData] = useState<MonthlySeriesData[] | null>(null);
    const { user } = useSelector((state: any) => state.auth);

    // Fetch yearly data for Bar chart 
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const res: MonthlySeriesData[] = await fetcher(`/resources/messages/${user.id}/messages/month-date`);
                setYearlyData(res);
            } catch (error) {
                console.error("Error fetching yearly data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [activeToggle, user.id]);

    // Fetch  data based on frequency for donut chart 
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const res: FrequencyBasedResponse = await fetcher(`/resources/messages/${user.id}/messages?frequency=${activeToggle}`);
                setData(res);
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [activeToggle, user.id]);

    // Fetch  data for last60Days frequency for time saving 
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const res: FrequencyBasedResponse = await fetcher(`/resources/messages/${user.id}/messages?frequency=last60days`);
                setSixtydays(res);
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [activeToggle, user.id]);

    // Fetch  data for las30Days frequency for time saving 
    // useEffect(() => {
    //     const fetchData = async () => {
    //         setLoading(true);
    //         try {
    //             const res: FrequencyBasedResponse = await fetcher(`/resources/messages/${user.id}/messages?frequency=monthly`);
    //             setThirtyDays(res);
    //         } catch (error) {
    //             console.error("Error fetching data:", error);
    //         } finally {
    //             setLoading(false);
    //         }
    //     };

    //     fetchData();
    // }, [activeToggle, user.id]);

    const handleToggleMonthly = () => {
        setActiveToggle('monthly');
    };

    const handleToggleYearToDate = () => {
        setActiveToggle('yearToDate');
    };

    const handleToggleAllTime = () => {
        setActiveToggle('allTime');
    };

    const getDonutChartData = (): number[] => {
        if (!data) return [0, 0, 0];
        return [Number(data.approved), Number(data.evaluating), Number(data.blocked)];
    };

    const calculateTimeSavings = (
        data: FrequencyBasedResponse | null,
        sixtyDays: FrequencyBasedResponse | null
    ) => {
        const frequencyBasedData = data ? timeSavings(data.evaluating, data.blocked) : { hours: 0, minutes: 0 };
        const last60Days = sixtyDays ? timeSavings(sixtyDays.evaluating, sixtyDays.blocked) : { hours: 0, minutes: 0 };
        return { frequencyBasedData, last60Days };
    };
    
    const { frequencyBasedData, last60Days } = calculateTimeSavings(data, sixtyDays);

    const barChartCategories = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    // Bar Chart series
    const barChartData = (): MonthlySeriesData[] => {
        if (!yearlyData || yearlyData.length === 0) {
            return [
                { name: 'Approved', data: Array(12).fill(0) },
                { name: 'Evaluating', data: Array(12).fill(0) },
                { name: 'Blocked', data: Array(12).fill(0) },
            ];
        }

        return yearlyData;
    };
    const barChartSeries = barChartData();

    if (loading) {
        return <div className="flex justify-center items-center h-screen"><Loader /></div>;
    }


    return (
        <div className="h-full w-full flex flex-col min-h-screen">
            <div className="py-6 md:px-28 px-4 w-full">
                <div className="flex flex-col md:flex-row gap-4 justify-center md:justify-between sm:items-center w-full">
                    <p className='text-2xl font-bold'>Stats</p>
                    <div className="flex flex-col md:flex-row border-2 px-5 py-3 border-green_primary rounded-md justify-between md:space-x-5 space-y-5 md:space-y-0">
                        <div className="flex justify-between space-x-4">
                            <span>Last 30 days</span>
                            <ToggleButton handleToggle={handleToggleMonthly} enabled={activeToggle === 'monthly'} />
                        </div>
                        <div className="flex justify-between space-x-4">
                            <span>Year to date</span>
                            <ToggleButton handleToggle={handleToggleYearToDate} enabled={activeToggle === 'yearToDate'} />
                        </div>
                        <div className="flex justify-between space-x-4">
                            <span>All time</span>
                            <ToggleButton handleToggle={handleToggleAllTime} enabled={activeToggle === 'allTime'} />
                        </div>
                    </div>
                </div>
                <div className='max-h-fit flex flex-col lg:flex-row justify-center w-full lg:space-x-10 mt-10'>
                    <div className='w-full p-4 rounded-md shadow-lg border border-gray4'>
                        <p className='text-lg font-bold'>Time savings</p>
                        <div className={`flex md:mx-20 ${activeToggle === "allTime" ? "justify-start" : activeToggle === "yearToDate" ? "justify-start" : "justify-between"} mt-5`}>
                            <div className='md:pb-2'>
                            <p className='text-lg text-gray2 font-bold'>{`${activeToggle === "allTime" ? "All time" : activeToggle === "yearToDate" ? "Year to date" : "Last 30 days"}`}</p>
                                <div className='flex justify-center items-center mt-4 -ml-2'>
                                    <img src={frequencyBasedData.hours > 0 || frequencyBasedData.minutes ? ClockGreen : ClockGray} className='w-6 h-6 md:w-10 md:h-10 mr-1' />
                                    <p className={`text-lg md:text-2xl font-bold ${frequencyBasedData.hours > 0 || frequencyBasedData.minutes > 0
                                        ? "text-green_primary" : "text-gray1"}`}>{frequencyBasedData.hours > 0 || frequencyBasedData.minutes > 0
                                            ? `${frequencyBasedData.hours}h ${frequencyBasedData.minutes}m`
                                            : "--"}
                                    </p>
                                </div>
                            </div>
                            <div className={`${activeToggle==='yearToDate' || activeToggle==='allTime' ? "hidden" : ""}`}>
                                <p className='text-lg text-gray2 font-bold'>Last 60 days</p>
                                <div className='flex justify-center items-center mt-4 -ml-2'>
                                    <img src={last60Days.hours > 0 || last60Days.minutes ? ClockGreen : ClockGray} className='w-6 h-6 md:w-10 md:h-10 mr-1' />
                                    <p className={`text-lg md:text-2xl font-bold ${last60Days.hours > 0 || last60Days.minutes > 0
                                        ? "text-green_primary" : "text-gray1"}`}>{last60Days.hours > 0 || last60Days.minutes > 0
                                            ? `${last60Days.hours}h ${last60Days.minutes}m`
                                            : "--"}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='w-full h-full lg:max-w-md p-4 rounded-md shadow-lg border border-gray4 mt-5 lg:mt-0'>
                        <p className='text-lg font-bold'>Shielded from inbox</p>
                        <div>
                            <DonutChart series={getDonutChartData()} />
                        </div>
                    </div>
                </div>
                <div className='flex flex-col w-full rounded-md shadow-lg border border-gray4 mt-10'>
                    <div className='w-full flex flex-col md:flex-row justify-between items-center p-4'>
                        <p className='text-lg font-bold'>Month over month</p>
                        <div className='flex space-x-5'>
                            <div className='flex space-x-2 items-center'>
                                <div className='flex w-1.5 h-1.5 rounded-full bg-green_primary_text'></div>
                                <p className='text-green_primary'>Approved</p>
                            </div>
                            <div className='flex space-x-2 items-center'>
                                <div className='flex w-1.5 h-1.5 rounded-full bg-yellow100'></div>
                                <p className='text-yellow100'>Evaluating</p>
                            </div>
                            <div className='flex space-x-2 items-center'>
                                <div className='flex w-1.5 h-1.5 rounded-full bg-red_error'></div>
                                <p className='text-red_error'>Blocked</p>
                            </div>
                        </div>
                    </div>
                    <div className='w-full overflow-x-auto'>
                        <BarChart series={barChartSeries} categories={barChartCategories} />
                    </div>
                </div>
                <div className='mt-10 flex flex-col'>
                    <p className='text-gray1 font-semibold text-sm'>Footnotes:</p>
                    <li className='text-gray2 text-sm list-disc'>Email filtering is updated in real time. Using drag & drop to change the status of a sender will affect the reported # of filtered emails.</li>
                    <li className='text-gray2 text-sm list-disc mt-2'>Formula for time savings = Total # of Blocked emails + Total # of Evaluating emails over a given time period X 10 seconds / 3600</li>
                </div>
            </div>
        </div>
    );
}

export default StatsDashboard;
