import emailIcon from "./../ui/assets/icons/email.svg";
import bellIcon from "./../ui/assets/icons/bell.svg";
import listIcon from "./../ui/assets/icons/list.svg";
import HomeIcon from "./../ui/assets/icons/homeIcon.svg";

interface RouteTitles {
  [key: string]: string;
}
interface PrivateMenuBarItem {
  title: string;
  shortTitle: string;
  to: string;
  icon: string;
}
interface LoadingCardStatus {
  PENDING: string;
  IN_PROGRESS: string;
  COMPLETED: string;
}

export const PRIVATE_MENUBAR_ITEMS: PrivateMenuBarItem[] = [
  {
    title: "Stats",
    shortTitle: "Stats",
    to: "stats",
    icon: HomeIcon,
  },
  {
    title: "Email accounts",
    shortTitle: "Accounts",
    to: "email-accounts",
    icon: emailIcon,
  },
  { title: "Requests", shortTitle: "Requests", to: "requests", icon: bellIcon },
  {
    title: "Approved sender list",
    shortTitle: "Senders",
    to: "approved-sender-list",
    icon: listIcon,
  },
];

export const LOADING_CARD_STATUS: LoadingCardStatus = {
  PENDING: "pending",
  IN_PROGRESS: "in_progress",
  COMPLETED: "completed",
};

export const ROUTE_TITLES: RouteTitles = {
  "/login": "Sign in",
  "/register": "Create an account",
  "/forgot-password": "Reset password",
  "/reset-password": "Reset password",
};
