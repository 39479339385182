import React from 'react';

interface ToggleButtonProps {
    enabled: boolean;
    handleToggle: () => void;
}

const ToggleButton: React.FC<ToggleButtonProps> = ({ enabled, handleToggle }) => {
    return (
        <div
            onClick={handleToggle}
            className={`relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 ${enabled ? 'border-green_primary' : 'border-gray2'} transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 ${enabled ? 'bg-green_primary' : 'bg-gray2'}`}
        >
            <span className="sr-only">Use setting</span>
            <span
                aria-hidden="true"
                className={`pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out ${enabled ? 'translate-x-5' : 'translate-x-0'}`}
            />
        </div>
    );
}

export default ToggleButton;
